// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-js": () => import("./../../../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-recent-transactions-js": () => import("./../../../src/pages/recent-transactions.js" /* webpackChunkName: "component---src-pages-recent-transactions-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

